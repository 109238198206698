import * as React from "react"
import { Link } from "gatsby"
import Navigation from "./Navigation"
import eablogo from "../../assets/images/eab-logo-color.svg"
import "../../styles/sass/Showcase/styles-showcase.scss"

const Header = ({ siteTitle }) => (
  <header
      className="site-header"
    >
      <div className="eab-logo">
        <Link to="/">
          <img src={eablogo} alt="EAB Global" />
        </Link>
        <div className="agency-services">Agency Services</div>
      </div>
      <Navigation  />
    </header>
)

export default Header
