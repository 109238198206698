import React from "react"

import { useState } from "react"

import { Link } from "gatsby"

const Navigation = () => {
  const [toggle, setToggle] = useState(false)
  const onClick = () => setToggle(!toggle)
//   const [pageUrl, setPage] = useState("not-internal")

//   useEffect(() => {
//     const url = window.location.href
//     if (url.includes("internal")) {
//       setPage("/internal")
//     } else {
//       setPage("")
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [])
  // console.log("locations in the nav: ", locations)
//   const navItems =
//     locations &&
//     locations.map((location, index) => (
//       <li key={index}>
//         <Link to={`${pageUrl}/location/${location.loc_id}`}>
//           {location.name}
//         </Link>
//       </li>
//     ))

  return (
    <div className={toggle ? "navBar show-nav" : "navBar"}>
      <button className="nav-toggle" onClick={onClick}>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
      </button>
      <nav role="navigation">
        <ul className="no-list">
          <li>
            <Link className="view_all" to="/">
              All Steps
            </Link>
            <ul>
                <li>
                    <Link to="/discovery-and-planning">Discovery & Planning</Link>
                </li>
                <li>
                    <Link to="/information-architecture">Information Architecture, Navigation & Global Content Strategy</Link>
                </li>
                <li>
                    <Link to="/ux-design">UX Design: Wireframes, Prototyping & User Testing</Link>
                </li>
                <li>
                    <Link to="/visual-design">Visual Design</Link>
                </li>
                <li>
                    <Link to="/content-strategy">On-page Content Strategy, Copywriting & Production Planning</Link>
                </li>
                <li>
                    <Link to="/seo-analytics-and-reporting">SEO Analytics & Reporting</Link>
                </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  )
}
export default Navigation
