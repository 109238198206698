import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Cta from '../CallToAction';

import Header from "./header"
import { ModalContainer } from 'reoverlay';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="content-wrapper">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div className="content">
        <main>{children}</main>
        <footer className="site-footer">
          <div className="wrapper">
            <p>© {new Date().getFullYear()} EAB. All Rights Reserved</p>
            {/* <a href="#">Contact Us</a> */}
          </div>
        </footer>
      </div>
          <div className="request-info-cta">
            <h2>Ready to transform your institution?</h2>{' '}
            <Cta buttonText="Request Information">Request Information</Cta>
          </div>
      <ModalContainer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
